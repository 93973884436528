import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';

import EventDataSourceFormFields from '../EventDataSourceFormFields';
import {
  FormData,
  defaultValues,
  fieldsConfig,
  useInitializeFormFieldValues,
} from '../eventDataSourceFormConfig';
import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import { useGetDataSourcesQuery } from 'reducers/data-sources';
import { useGetEventByIdQuery, useUpdateEventMutation } from 'reducers/events';
import { EventInput } from 'types';
import FormButtons from 'components/FormButtons';

export default function EventDataSourceEdit() {
  const navigate = useNavigate();
  const form = useForm<FormData>({ defaultValues });

  const { eventId, eventDataSourceId } = useParams();

  const {
    isLoading: getEventLoading,
    error: getEventError,
    data: event,
  } = useGetEventByIdQuery(eventId!);

  const {
    isLoading: getDataSourcesLoading,
    error: getDataSourcesError,
    data: dataSources,
  } = useGetDataSourcesQuery({});

  const eventDataSource = React.useMemo(() => {
    return event?.data_sources.find(ds => ds.id === eventDataSourceId);
  }, [event, eventDataSourceId]);

  const [
    updateEvent,
    {
      isLoading: updateEventLoading,
      error: updateEventError,
      isSuccess: updateEventSuccess,
    },
  ] = useUpdateEventMutation();

  React.useEffect(() => {
    if (updateEventSuccess) navigate(-1);
  }, [updateEventSuccess, navigate]);

  useInitializeFormFieldValues(eventDataSource, form);

  const onSubmit = async (formData: FormData) => {
    const eventInput = {
      ...event,
      data_sources: event!.data_sources.map(ds => {
        if (ds.id === eventDataSourceId) {
          return {
            ...ds,
            ...formData,
          };
        }
        return ds;
      }),
    }
    await updateEvent(eventInput as EventInput);
  };

  if (
    getEventLoading ||
    getDataSourcesLoading ||
    updateEventLoading
  ) return <LoadingIndicator />;
  if (
    getEventError ||
    getDataSourcesError ||
    updateEventError
  ) throw (
    getEventError ||
    getDataSourcesError ||
    updateEventError
  );

  return (
    <>
      <PageHeader title="Edit Event Data Source" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <EventDataSourceFormFields
            fieldsConfig={fieldsConfig}
            dataSources={dataSources!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={updateEventLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
}