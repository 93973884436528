import Layout from 'components/Layout';
import AwardList from 'features/Award/AwardList';
import AwardNew from 'features/Award/AwardNew';
import AwardEdit from 'features/Award/AwardEdit';
import AwardDetail from 'features/Award/AwardDetail';
import TeamList from 'features/Team/TeamList';
import TeamNew from 'features/Team/TeamNew';
import TeamEdit from 'features/Team/TeamEdit';
import TeamMerge from 'features/Team/TeamMerge';
import TeamDetail from 'features/Team/TeamDetail';
import DriverAwardList from 'features/DriverAward/DriverAwardList';
import DriverAwardNew from 'features/DriverAward/DriverAwardNew';
import DriverAwardEdit from 'features/DriverAward/DriverAwardEdit';
import DriverAwardDetail from 'features/DriverAward/DriverAwardDetail';
import DriverList from 'features/Driver/DriverList';
import DriverNew from 'features/Driver/DriverNew';
import DriverEdit from 'features/Driver/DriverEdit';
import DriverMerge from 'features/Driver/DriverMerge';
import DriverDetail from 'features/Driver/DriverDetail';
import DataSourceList from 'features/DataSource/DataSourceList';
import DataSourceNew from 'features/DataSource/DataSourceNew';
import DataSourceDetail from 'features/DataSource/DataSourceDetail';
import DataSourceEdit from 'features/DataSource/DataSourceEdit';
import EventList from 'features/Event/EventList';
import EventNew from 'features/Event/EventNew';
import EventEdit from 'features/Event/EventEdit';
import EventDetail from 'features/Event/EventDetail';
import SeriesList from 'features/Series/SeriesList';
import SeriesNew from 'features/Series/SeriesNew';
import SeriesEdit from 'features/Series/SeriesEdit';
import SeriesDetail from 'features/Series/SeriesDetail';
import SeriesAliasList from 'features/SeriesAlias/SeriesAliasList';
import SeriesAliasNew from 'features/SeriesAlias/SeriesAliasNew';
import SeriesAliasEdit from 'features/SeriesAlias/SeriesAliasEdit';
import SeriesAliasDetail from 'features/SeriesAlias/SeriesAliasDetail';
import SessionList from 'features/Session/SessionList';
import SessionNew from 'features/Session/SessionNew';
import SessionEdit from 'features/Session/SessionEdit';
import SessionDetail from 'features/Session/SessionDetail';
import SessionResultList from 'features/SessionResult/SessionResultList';
import SessionResultNew from 'features/SessionResult/SessionResultNew';
import SessionResultEdit from 'features/SessionResult/SessionResultEdit';
import SessionResultDetail from 'features/SessionResult/SessionResultDetail';
import OrganizationList from 'features/Organization/OrganizationList';
import OrganizationNew from 'features/Organization/OrganizationNew';
import OrganizationEdit from 'features/Organization/OrganizationEdit';
import OrganizationMerge from 'features/Organization/OrganizationMerge';
import OrganizationDetail from 'features/Organization/OrganizationDetail';
import TrackList from 'features/Track/TrackList';
import TrackNew from 'features/Track/TrackNew';
import TrackEdit from 'features/Track/TrackEdit';
import TrackDetail from 'features/Track/TrackDetail';
import TrackConfigurationList from 'features/TrackConfiguration/TrackConfigurationList';
import TrackConfigurationNew from 'features/TrackConfiguration/TrackConfigurationNew';
import TrackConfigurationEdit from 'features/TrackConfiguration/TrackConfigurationEdit';
import TrackConfigurationDetail from 'features/TrackConfiguration/TrackConfigurationDetail';
import AuthRoute from 'components/AuthRoute';
import { fetchUserPermissions } from 'helpers/auth';
import SeriesDataSourceNew from 'features/SeriesDataSource/SeriesDataSourceNew';
import SeriesDataSourceList from 'features/SeriesDataSource/SeriesDataSourceList';
import SeriesDataSourceEdit from 'features/SeriesDataSource/SeriesDataSourceEdit';
import SeriesDataSourceDetail from 'features/SeriesDataSource/SeriesDataSourceDetail';
import EventDataSourcesList from 'features/Event/EventDataSource/EventDataSourceList';
import EventDataSourceDetail from 'features/Event/EventDataSource/EventDataSourceDetail';
import EventDataSourceEdit from 'features/Event/EventDataSource/EventDataSourceEdit';
import EventDataSourceNew from 'features/Event/EventDataSource/EventDataSourceNew';

const routes = [
  {
    id: 'root',
    path: '/',
    element: <Layout />,
    loader: fetchUserPermissions,
    children: [
      {
        path: 'drivers',
        children: [
          { index: true, element: <DriverList /> },
          {
            path: 'new',
            element: (
              <AuthRoute permissions={['modify']}>
                <DriverNew />
              </AuthRoute>
            ),
          },
          {
            path: ':driverId/edit',
            element: (
              <AuthRoute permissions={['modify']}>
                <DriverEdit />
              </AuthRoute>
            ),
          },
          {
            path: ':driverId/merge',
            element: (
              <AuthRoute permissions={['modify']}>
                <DriverMerge />
              </AuthRoute>
            ),
          },
          { path: ':driverId', element: <DriverDetail /> },
        ],
      },
      {
        path: 'driver-awards',
        children: [
          { index: true, element: <DriverAwardList title="Driver Awards" /> },
          {
            path: 'new',
            element: (
              <AuthRoute permissions={['modify']}>
                <DriverAwardNew />
              </AuthRoute>
            ),
          },
          {
            path: ':driverAwardId/edit',
            element: (
              <AuthRoute permissions={['modify']}>
                <DriverAwardEdit />
              </AuthRoute>
            ),
          },
          { path: ':driverAwardId', element: <DriverAwardDetail /> },
        ],
      },
      {
        path: 'data-sources',
        children: [
          { index: true, element: <DataSourceList /> },
          {
            path: 'new',
            element: (
              <AuthRoute permissions={['modify']}>
                <DataSourceNew />
              </AuthRoute>
            ),
          },
          {
            path: ':dataSourceId',
            children: [
              {
                index: true,
                element: (
                  <AuthRoute permissions={['modify']}>
                    <DataSourceDetail />
                  </AuthRoute>
                ),
              },
              {
                path: 'edit',
                element: (
                  <AuthRoute permissions={['modify']}>
                    <DataSourceEdit />
                  </AuthRoute>
                ),
              },
              {
                path: 'series',
                children: [
                  {
                    index: true,
                    element: (
                      <AuthRoute permissions={['modify']}>
                        <SeriesDataSourceList />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: 'new',
                    element: (
                      <AuthRoute permissions={['modify']}>
                        <SeriesDataSourceNew />
                      </AuthRoute>
                    ),
                  },
                ]
              },
            ],
          },
        ],
      },
      {
        path: 'events',
        children: [
          { index: true, element: <EventList /> },
          {
            path: 'new',
            element: (
              <AuthRoute permissions={['modify']}>
                <EventNew />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: 'series',
        children: [
          { index: true, element: <SeriesList /> },
          {
            path: 'new',
            element: (
              <AuthRoute permissions={['modify']}>
                <SeriesNew />
              </AuthRoute>
            ),
          },
          {
            path: ':seriesId/edit',
            element: (
              <AuthRoute permissions={['modify']}>
                <SeriesEdit />
              </AuthRoute>
            ),
          },
          {
            path: ':seriesId',
            children: [
              { index: true, element: <SeriesDetail /> },
              {
                path: 'aliases',
                children: [
                  { index: true, element: <SeriesAliasList /> },
                  {
                    path: 'new',
                    element: (
                      <AuthRoute permissions={['modify']}>
                        <SeriesAliasNew />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ':seriesAliasId/edit',
                    element: (
                      <AuthRoute permissions={['modify']}>
                        <SeriesAliasEdit />
                      </AuthRoute>
                    ),
                  },
                  { path: ':seriesAliasId', element: <SeriesAliasDetail /> },
                ],
              },
              {
                path: 'awards',
                children: [
                  { index: true, element: <AwardList /> },
                  {
                    path: 'new',
                    element: (
                      <AuthRoute permissions={['modify']}>
                        <AwardNew />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ':awardId/edit',
                    element: (
                      <AuthRoute permissions={['modify']}>
                        <AwardEdit />
                      </AuthRoute>
                    ),
                  },
                  { path: ':awardId', element: <AwardDetail /> },
                ],
              },
              {
                path: 'teams',
                children: [
                  { index: true, element: <TeamList /> },
                  {
                    path: 'new',
                    element: (
                      <AuthRoute permissions={['modify']}>
                        <TeamNew />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ':teamId/edit',
                    element: (
                      <AuthRoute permissions={['modify']}>
                        <TeamEdit />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ':teamId/merge',
                    element: (
                      <AuthRoute permissions={['modify']}>
                        <TeamMerge />
                      </AuthRoute>
                    ),
                  },
                  { path: ':teamId', element: <TeamDetail /> },
                ],
              },
              {
                path: 'events',
                children: [
                  { index: true, element: <EventList /> },
                  {
                    path: ':eventId/edit',
                    element: (
                      <AuthRoute permissions={['modify']}>
                        <EventEdit />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ':eventId',
                    children: [
                      { index: true, element: <EventDetail /> },
                      {
                        path: 'sessions',
                        children: [
                          { index: true, element: <SessionList /> },
                          {
                            path: 'new',
                            element: (
                              <AuthRoute permissions={['modify']}>
                                <SessionNew />
                              </AuthRoute>
                            ),
                          },
                          {
                            path: ':sessionId/edit',
                            element: (
                              <AuthRoute permissions={['modify']}>
                                <SessionEdit />
                              </AuthRoute>
                            ),
                          },
                          {
                            path: ':sessionId',
                            children: [
                              { index: true, element: <SessionDetail /> },
                              {
                                path: 'results',
                                children: [
                                  { index: true, element: <SessionResultList /> },
                                  {
                                    path: 'new',
                                    element: (
                                      <AuthRoute permissions={['modify']}>
                                        <SessionResultNew />
                                      </AuthRoute>
                                    ),
                                  },
                                  {
                                    path: ':sessionResultId/edit',
                                    element: (
                                      <AuthRoute permissions={['modify']}>
                                        <SessionResultEdit />
                                      </AuthRoute>
                                    ),
                                  },
                                  { path: ':sessionResultId', element: <SessionResultDetail /> },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: 'data-sources',
                        children: [
                          { index: true, element: <EventDataSourcesList /> },
                          {
                            path: ':eventDataSourceId',
                            children: [
                              { index: true, element: <EventDataSourceDetail /> },
                              {
                                path: 'edit',
                                element: (
                                  <AuthRoute permissions={['modify']}>
                                    <EventDataSourceEdit />
                                  </AuthRoute>
                                ),
                              },
                            ],
                          },
                          {
                            path: 'new',
                            element: (
                              <AuthRoute permissions={['modify']}>
                                <EventDataSourceNew />
                              </AuthRoute>
                            ),
                          }
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: 'data-sources',
                children: [
                  {
                    index: true,
                    element: (
                      <SeriesDataSourceList showSeriesColumn={false} />
                    )
                  },
                  {
                    path: ':seriesDataSourceId',
                    children: [
                      { index: true, element: <SeriesDataSourceDetail /> },
                      {
                        path: 'edit',
                        element: (
                          <AuthRoute permissions={['modify']}>
                            <SeriesDataSourceEdit />
                          </AuthRoute>
                        ),
                      }
                    ]
                  }
                ]
              }
            ]
          },
        ],
      },
      {
        path: 'organizations',
        children: [
          { index: true, element: <OrganizationList /> },
          {
            path: 'new', element: (
              <AuthRoute permissions={['modify']}>
                <OrganizationNew />
              </AuthRoute>
            ),
          },
          {
            path: ':organizationId/edit', element: (
              <AuthRoute permissions={['modify']}>
                <OrganizationEdit />
              </AuthRoute>
            ),
          },
          {
            path: ':organizationId/merge', element: (
              <AuthRoute permissions={['modify']}>
                <OrganizationMerge />
              </AuthRoute>
            ),
          },
          { path: ':organizationId', element: <OrganizationDetail /> },
        ],
      },
      {
        path: 'tracks',
        children: [
          { index: true, element: <TrackList /> },
          {
            path: 'new',
            element: (
              <AuthRoute permissions={['modify']}>
                <TrackNew />
              </AuthRoute>
            ),
          },
          {
            path: ':trackId/edit',
            element: (
              <AuthRoute permissions={['modify']}>
                <TrackEdit />
              </AuthRoute>
            ),
          },
          {
            path: ':trackId',
            children: [
              { index: true, element: <TrackDetail /> },
              {
                path: 'configurations',
                children: [
                  { index: true, element: <TrackConfigurationList /> },
                  {
                    path: 'new',
                    element: (
                      <AuthRoute permissions={['modify']}>
                        <TrackConfigurationNew />
                      </AuthRoute>
                    ),
                  },
                  {
                    path: ':trackConfigurationId/edit',
                    element: (
                      <AuthRoute permissions={['modify']}>
                        <TrackConfigurationEdit />
                      </AuthRoute>
                    ),
                  },
                  { path: ':trackConfigurationId', element: <TrackConfigurationDetail /> },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
