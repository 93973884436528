import React from 'react';
import {
  Row,
  Col,
} from 'react-bootstrap';

import { Series } from 'types';

import CheckboxField from 'components/FormFields/CheckboxField';
import SelectField from 'components/FormFields/SelectField';
import TextField from 'components/FormFields/TextField';
import { fieldsConfig } from '../seriesDataSourceFormConfig';

interface Props {
  fieldsConfig: typeof fieldsConfig;
  series: Series[];
  disabled: boolean;
}

function SeriesDataSourceFormFields(props: Props) {
  return (
    <Row>
      <Col xs={12} lg={2}>
        <SelectField
          config={props.fieldsConfig.series_id}
          options={props.series.map(t => ({ label: t.abbreviation, value: t.id }))}
          disabled={props.disabled}
        />
      </Col>

      <Col xs={12} lg={2}>
        <CheckboxField
          config={props.fieldsConfig.is_primary}
          disabled={props.disabled}
        />
      </Col>

      <Col xs={12} lg={2}>
        <CheckboxField
          config={props.fieldsConfig.is_test}
          disabled={props.disabled}
        />
      </Col>

      <Col xs={12} lg={2}>
        <TextField
          config={props.fieldsConfig.year}
          disabled={props.disabled}
        />
      </Col>
    </Row>
  );
}

SeriesDataSourceFormFields.defaultProps = {
  disabled: false,
};

export default SeriesDataSourceFormFields;
