import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'qs';

import type {
  DataSource,
  DataSourceInput,
  DataSourceSearchQuery,
  DataSourceSearchResult,
} from 'types';
import { BASE_URL } from 'config';

export const dataSourcesApi = createApi({
  reducerPath: 'dataSourcesApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['DataSource'],
  endpoints: (build) => ({
    getDataSources: build.query<DataSourceSearchResult, DataSourceSearchQuery>({
      query: (queryParams) => `data-sources?${qs.stringify(queryParams)}`,
      providesTags: (result) =>
        result!.data
          ? [
            ...result!.data.map(({ id }) => ({ type: 'DataSource' as const, id })),
            { type: 'DataSource', id: 'LIST' },
          ]
          : [{ type: 'DataSource', id: 'LIST' }],
    }),
    getDataSourceById: build.query<DataSource, string>({
      query: (id) => `data-sources/${id}`,
      providesTags: (result, error, id) => [{ type: 'DataSource', id }],
    }),
    createDataSource: build.mutation<DataSource, DataSourceInput>({
      query: (body) => {
        return {
          url: 'data-sources',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ type: 'DataSource', id: 'LIST' }],
    }),
    updateDataSource: build.mutation<DataSource, DataSourceInput>({
      query: (body) => ({
        url: `data-sources/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'DataSource', id }],
    }),
    deleteDataSource: build.mutation<void, string>({
      query: (id) => ({
        url: `data-sources/${id}`,
        method: 'DELETE',
        responseHandler: 'text', // No body is returned so don't parse as JSON
      }),
      invalidatesTags: (result, error, id) => [{ type: 'DataSource', id }],
    }),
  }),
});

export const {
  useGetDataSourcesQuery,
  useGetDataSourceByIdQuery,
  useCreateDataSourceMutation,
  useUpdateDataSourceMutation,
  useDeleteDataSourceMutation,
} = dataSourcesApi;
