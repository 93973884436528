import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import LoadingIndicator from 'components/LoadingIndicator';
import PageHeader from 'components/PageHeader';
import FormButtons from 'components/FormButtons';
import {
  defaultValues,
  fieldsConfig,
  FormData,
  formatPayloadFromFormData,
} from 'features/SeriesDataSource/seriesDataSourceFormConfig';
import { useGetSeriesQuery } from 'reducers/series';
import SeriesDataSourceFormFields from '../SeriesDataSourceFormFields';
import { useCreateSeriesDataSourceMutation } from 'reducers/series-data-sources';

export default function SeriesDataSourceNew() {
  const navigate = useNavigate();
  const form = useForm<FormData>({ defaultValues });

  const { seriesId, dataSourceId } = useParams();

  const {
    isLoading: seriesLoading,
    error: seriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const [
    createSeriesDataSource,
    {
      isLoading: createSeriesDataSourceLoading,
      error: createSeriesDataSourceError,
      isSuccess: createSeriesDataSourceSuccess,
    },
  ] = useCreateSeriesDataSourceMutation();

  React.useEffect(() => {
    form.setValue('series_id', seriesId!);
  }, [form, seriesId]);

  React.useEffect(() => {
    form.setValue('data_source_id', dataSourceId!);
  }, [form, dataSourceId]);

  React.useEffect(() => {
    if (createSeriesDataSourceSuccess) navigate(-1);
  }, [createSeriesDataSourceSuccess, navigate]);

  const onSubmit = async (formData: FormData) => {
    const payload = formatPayloadFromFormData(formData);
    await createSeriesDataSource(payload);
  };

  if (
    seriesLoading ||
    createSeriesDataSourceLoading
  ) return <LoadingIndicator />;
  if (
    seriesError ||
    createSeriesDataSourceError
  ) throw (seriesError || createSeriesDataSourceError);

  return (
    <>
      <PageHeader title="Add Series Data Source" />

      <FormProvider {...form}>
        <Form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <SeriesDataSourceFormFields
            fieldsConfig={fieldsConfig}
            series={seriesData!.data}
          />

          <FormButtons
            submitHandler={form.handleSubmit(onSubmit)}
            isLoading={createSeriesDataSourceLoading}
          />
        </Form>
      </FormProvider>
    </>
  );
};
