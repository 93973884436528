import React from 'react';
import {
  Row,
  Col,
} from 'react-bootstrap';

import TextField from 'components/FormFields/TextField';
import { fieldsConfig } from '../dataSourceFormConfig';

interface Props {
  fieldsConfig: typeof fieldsConfig;
  disabled: boolean;
}

function DataSourceFormFields(props: Props) {
  return (
    <>
      <Row>
        <Col xs={12} lg={2}>
          <TextField
            config={props.fieldsConfig.type}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} lg={5}>
          <TextField
            config={props.fieldsConfig.source}
            disabled={props.disabled}
          />
        </Col>

        <Col xs={12} lg={5}>
          <TextField
            config={props.fieldsConfig.display_name}
            disabled={props.disabled}
          />
        </Col>
      </Row>
    </>
  );
}

DataSourceFormFields.defaultProps = {
  disabled: false,
};

export default DataSourceFormFields;
