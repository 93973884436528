import { useEffect } from 'react';

import stringToNumber from 'helpers/stringToNumber';
import numberToString from 'helpers/numberToString';
import msToTime from 'helpers/msToTime';
import timeToMs from 'helpers/timeToMs';
import { Event, EventInput } from 'types';

export const fieldsConfig = {
  name: {
    name: 'name',
    label: 'Name',
    validation: {
      required: true,
    },
  },
  year: {
    name: 'year',
    label: 'Year',
    validation: {
      required: true,
      pattern: /^\d{4}$/,
    },
    validationMessages: {
      pattern: 'Must be a 4-digit year',
    }
  },
  is_test: {
    name: 'is_test',
    label: 'Test Event',
    validation: {
      required: true,
    },
  },
  series_id: {
    name: 'series_id',
    label: 'Series',
    validation: {
      required: true,
    },
  },
  event_start: {
    name: 'event_start',
    label: 'Start',
    validation: {
      pattern: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/,
    },
    validationMessages: {
      pattern: 'Must be in the format "yyyy-mm-ddThh:mm:ss.SSSZ"'
    },
  },
  event_end: {
    name: 'event_end',
    label: 'End',
    validation: {
      pattern: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/,
    },
    validationMessages: {
      pattern: 'Must be in the format "yyyy-mm-ddThh:mm:ss.SSSZ"'
    },
  },
  series_event_id: {
    name: 'series_event_id',
    label: 'Series Event ID',
  },
  trd_event_id: {
    name: 'trd_event_id',
    label: 'TRD Event ID',
  },
  total_laps: {
    name: 'total_laps',
    label: 'Total Laps',
    validation: {
      pattern: /^\d+$/,
    },
    validationMessages: {
      pattern: 'Must be a number',
    },
  },
  duration: {
    name: 'duration',
    label: 'Duration',
    validation: {
      pattern: /\d{2}:\d{2}:\d{2}\.\d{3}/,
    },
    validationMessages: {
      pattern: 'Must be in the format "hh:mm:ss.SSS"',
    },
  },
  target_lap_time: {
    name: 'target_lap_time',
    label: 'Target Lap Time',
    validation: {
      pattern: /\d{2}:\d{2}:\d{2}\.\d{3}/,
    },
    validationMessages: {
      pattern: 'Must be in the format "hh:mm:ss.SSS"',
    },
  },
  track_id: {
    name: 'track_id',
    label: 'Track',
    validation: {
      required: true,
    },
  },
  track_configuration_id: {
    name: 'track_configuration_id',
    label: 'Track Configuration',
    validation: {
      required: true,
    },
  },
  track_photo_url: {
    name: 'track_photo_url',
    label: 'Track Photo URL',
  },
};

export interface FormData {
  name: string;
  year: string;
  is_test: string;
  series_id: string;
  event_start: string;
  event_end: string;
  series_event_id: string;
  trd_event_id: string;
  total_laps: string;
  duration: string;
  target_lap_time: string;
  track_id: string;
  track_configuration_id: string;
  track_photo_url: string;
};

export const defaultValues = {
  name: '',
  year: '',
  is_test: '',
  series_id: '',
  event_start: '',
  event_end: '',
  series_event_id: '',
  trd_event_id: '',
  total_laps: '',
  duration: '',
  target_lap_time: '',
  track_id: '',
  track_configuration_id: '',
  track_photo_url: '',
};

export const useInitializeFormFieldValues = (event: Event | undefined, form: any) => {
  useEffect(() => {
    if (!event) return;

    form.setValue('name', event.name);
    form.setValue('is_test', String(event.is_test));
    form.setValue('series_id', event.series_id);
    form.setValue('series_event_id', event.series_event_id ?? '');
    form.setValue('trd_event_id', event.trd_event_id ?? '');
    form.setValue('track_id', event.track_configuration.track_id);
    form.setValue('track_configuration_id', event.track_configuration.id);
    form.setValue('year', numberToString(event.year));
    form.setValue('event_start', event.event_start ?? '');
    form.setValue('event_end', event.event_end ?? '');
    form.setValue('duration', msToTime(event.duration));
    form.setValue('total_laps', numberToString(event.total_laps));
    form.setValue('target_lap_time', msToTime(event.target_lap_time));
    form.setValue('track_photo_url', event.track_photo_url ?? '');
  }, [event, form]);
};

export const formatPayloadFromFormData = (formData: FormData): EventInput => {
  return {
    name: formData.name,
    is_test: formData.is_test === 'true',
    series_id: formData.series_id,
    year: stringToNumber(formData.year),
    event_start: formData.event_start || null,
    event_end: formData.event_end || null,
    series_event_id: formData.series_event_id || null,
    trd_event_id: formData.trd_event_id || null,
    total_laps: stringToNumber(formData.total_laps),
    duration: timeToMs(formData.duration),
    target_lap_time: timeToMs(formData.target_lap_time),
    track_configuration_id: formData.track_configuration_id,
    track_photo_url: formData.track_photo_url || null,
  };
};
