import { useEffect } from 'react';

import { DataSource, DataSourceInput } from 'types';

export const fieldsConfig = {
  type: {
    name: 'type',
    label: 'Type',
    validation: {
      required: true,
    },
  },
  source: {
    name: 'source',
    label: 'Source',
    validation: {
      required: true,
    },
  },
  display_name: {
    name: 'display_name',
    label: 'Display Name',
  },
};

export interface FormData {
  type: string;
  source: string;
  display_name: string;
};

export const defaultValues = {
  type: '',
  source: '',
  display_name: '',
};

export const useInitializeFormFieldValues = (dataSource: DataSource | undefined, form: any) => {
  useEffect(() => {
    if (!dataSource) return;

    form.setValue('type', dataSource.type);
    form.setValue('source', dataSource.source);
    form.setValue('display_name', String(dataSource.display_name));
  }, [dataSource, form]);
};

export const formatPayloadFromFormData = (formData: FormData): DataSourceInput => {
  return {
    type: formData.type,
    source: formData.source,
    display_name: formData.display_name || null,
  };
};
