import { FormProvider, useForm } from "react-hook-form";
import { Form, useNavigate, useParams } from "react-router-dom";

import SeriesDataSourceFormFields from "../SeriesDataSourceFormFields";
import {
  FormData,
  defaultValues,
  fieldsConfig,
  useInitializeFormFieldValues,
} from "../seriesDataSourceFormConfig";
import { useGetSeriesQuery } from "reducers/series";
import { useDeleteSeriesDataSourceMutation, useGetSeriesDataSourceByIdQuery } from "reducers/series-data-sources";
import LoadingIndicator from "components/LoadingIndicator";
import React from "react";
import PageHeader from "components/PageHeader";
import { Button } from "react-bootstrap";
import DeleteConfirmationButton from "components/DeleteConfirmationButton";

export default function SeriesDataSourceDetail() {
  const navigate = useNavigate();
  const { seriesDataSourceId } = useParams();

  const form = useForm<FormData>({ defaultValues });

  const {
    isLoading: getSeriesDataSourceLoading,
    error: getSeriesDataSourceError,
    data: seriesDataSource,
  } = useGetSeriesDataSourceByIdQuery(seriesDataSourceId!);

  const {
    isLoading: getSeriesLoading,
    error: getSeriesError,
    data: seriesData,
  } = useGetSeriesQuery();

  const [
    deleteSeriesDataSource,
    {
      isLoading: deleteSeriesDataSourceLoading,
      error: deleteSeriesDataSourceError,
      isSuccess: deleteSeriesDataSourceSuccess,
    }
  ] = useDeleteSeriesDataSourceMutation();

  React.useEffect(() => {
    if (deleteSeriesDataSourceSuccess) navigate(-1);
  }, [deleteSeriesDataSourceSuccess, navigate]);

  useInitializeFormFieldValues(seriesDataSource, form);

  if (
    getSeriesLoading ||
    getSeriesDataSourceLoading ||
    deleteSeriesDataSourceLoading
  ) return <LoadingIndicator />;

  if (
    getSeriesError ||
    getSeriesDataSourceError ||
    deleteSeriesDataSourceError
  ) throw (
    getSeriesError ||
    getSeriesDataSourceError ||
    deleteSeriesDataSourceError
  );

  return (
    <>
      <PageHeader title="Series Data Source">
        <Button
          onClick={() => navigate(`/series/${seriesDataSource?.series_id}/data-sources/${seriesDataSource?.id}/edit`)}
        >
          Edit series data source
        </Button>
        <DeleteConfirmationButton
          buttonText="Delete series data source"
          action={() => deleteSeriesDataSource(seriesDataSourceId!)}
        />
      </PageHeader>

      <FormProvider {...form}>
        <Form>
          <SeriesDataSourceFormFields
            fieldsConfig={fieldsConfig}
            series={seriesData!.data}
            disabled
          />
        </Form>
      </FormProvider>
    </>
  );
};
